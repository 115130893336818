import type { throttle } from '@antfu/utils';
import { reactive } from 'vue';
import type { Ref, UnwrapNestedRefs } from 'vue';
import type { DoctorInterface, ClinicInterface, ServiceData } from '../../../EastclinicVueApi';
import type ServiceCartInterface from '../../interfaces/ServiceCartInterface';
import { PageInfoService, ScheduleRequest, ScheduleService } from '../../../EastclinicVueApi';
// import Cart from './Cart';
// import type BookingResponseInterface from './api/BookingResponseInterface';
import BookingApi from './api/BookingApi';
import type { Patient } from '~/EastclinicVueApi';

export default class BookingService {
  protected data = ref({ slot: null, clinic: null, doctor: null, sessionId: null, type: null });
  // public Cart:Cart = new Cart();

  // public withDoctor(doctor:DoctorInterface):this {
  //   this.data.value.doctor = doctor;
  //   return this;
  // }

  // public withClinic(clinic:ClinicInterface|null):this {
  //   if (clinic) { this.data.value.clinic = clinic; }
  //   return this;
  // }

  // public withSlot(slot:number|null):this {
  //   this.data.value.slot = slot;
  //   return this;
  // }

  // public withPatient(patient:Patient):this {
  //   this.Patient = patient;
  //   return this;
  // }

  // public withTypeBooking(type:string):this {
  //   this.data.value.type = type;
  //   return this;
  // }

  public get doctor():DoctorInterface|null {
    return (this.data.value.doctor) ?? null;
  }

  public get clinic():ClinicInterface|null {
    return (this.data.value.clinic) ?? null;
  }

  public get slot():number|null {
    return (this.data.value.slot) ?? null;
  }

  public async book(dataToServer: BookingDataInterface) {
    // this errors check early
    // if (!this.doctor) throw new Error('Not set doctor to booking')
    // if (!this.selectedClinic) throw new Error('Not set clinic to booking')
    // if(!this.Patient.patientName) throw new Error('Not set patient name to booking')
    // if (!this.doctor) return null;
    if (!dataToServer.patient && !dataToServer.name && !dataToServer.surname && !dataToServer.patronymic) { throw new Error('not set patient data'); }
    const patronymic = (dataToServer?.patient?.patronymic) ?? null;
    const fio = (dataToServer?.patient?.fio) ? dataToServer?.patient.fio.trim().split(' ') : null;
    let surname = '';
    if (dataToServer?.patient?.surname) {
      surname = dataToServer?.patient?.surname;
    } else {
      surname = fio && fio[1] ? fio[1] : fio[0];
    }
    let name = '';
    if (dataToServer?.patient?.patientName) {
      name = dataToServer?.patient?.patientName;
    } else {
      name = fio?.[0];
    }
    // const name = dataToServer?.patient?.patientName ?? (fio?.[0] ?? null);

    const bookData = {
      key: 'a56f164d50be6d6164c6117a6b75cafe93cc3d43dc698861bdda75ab1d23809d',
      doctor: (dataToServer?.doctor?.id) ?? '',
      clinic: (dataToServer?.clinic?.id) ?? '',
      slot: (dataToServer?.slot) ?? '',
      name,
      surname,
      birthday: dataToServer?.patient?.birthday,
      patronymic: (patronymic) ?? null,
      phone: dataToServer?.patient.phone,
      sessionId: dataToServer?.sessionId,
      captcha: dataToServer?.captcha,
      services: dataToServer?.services,
      parking: dataToServer?.parking,
      comment: dataToServer?.comment,
      info: dataToServer?.info
    };

    if (dataToServer.onlyMessages) { bookData.onlyMessages = dataToServer.onlyMessages; }

    // if (this.data.value.sessionId) {
    //   bookData.sessionId = this.data.value.sessionId;
    // }
    // if (this.data.value.type) { bookData.type = this.data.value.type; }

    if (this.doctor?.is_cabinet) {
      bookData.onlyMessages = true;
    }

    const res = await new BookingApi().book(bookData);
    if (!res) { throw new Error('Ошибка сервера, попробуйте позже'); }
    useCartStore().clear();
    // if (!res.ok && res.error){
    //     throw new Error(res.error)
    // }
    return res;
  }
}
