export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('page:finish', () => {
    const { isHydrating } = useNuxtApp();
    if (isHydrating) {
      return;
    }
    if (history.state.scroll) {
      setTimeout(() => window.scrollTo(history.state.scroll), 1000);
    } else {
      setTimeout(() => window.scrollTo(0, 0), 100);
    }
  });
});
