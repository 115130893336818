declare global {
  interface Window {
    ym: (id: number, method: string, value: string) => void
  }
}

export const useYandexMetrika = () => {
  return useScript('/ym.js', {
    trigger: new Promise((resolve) => setTimeout(resolve, 3000)),
    key: 'ym',
    use() {
      return {
        context: useContext(),
        recordUrl(url: string) {
          if (this.context === 'prod') {
            window.ym(19981279, 'hit', url);
          }
        },
        reachGoal(goal: string) {
          if (this.context === 'prod') {
            window.ym(19981279, 'reachGoal', goal);
          }
        }
      };
    }
  });
};
