import buildGetURL from './UseGetParametersBuilder';

function addJwtTokenToRequest(options?: RequestInit) {
  if (typeof localStorage !== 'undefined' && localStorage && localStorage.getItem('jwtToken')) {
    const headers = options?.headers ? new Headers(options.headers) : new Headers();
    headers.set('Authorization', 'Bearer ' + localStorage.getItem('jwtToken'));
    if (options) { options.headers = headers; }
  }
  return options;
}

export function saveJWTToken(token:string) {
  // todo fill right jwt token string from server response format
  if (token && typeof localStorage !== 'undefined' && localStorage) {
    localStorage.setItem('jwtToken', token);
  }
}

export function deleteJWTToken() {
  // todo fill right jwt token string from server response format
  if (typeof localStorage !== 'undefined' && localStorage) {
    localStorage.setItem('jwtToken', '');
  }
}

export async function postToServer<T>(url: string, requestData: Object, options?: RequestInit) {
  if (!options) { options = {}; }
  options.method = 'POST';
  options.headers = { 'Content-Type': 'application/json', ...options.headers };
  options.body = JSON.stringify(requestData);
  return await fetchToServer(url, options);
}

export async function putToServer<T>(url: string, requestData: Object, options?: RequestInit) {
  if (!options) { options = {}; }
  options.method = 'put';
  options.headers = { 'Content-Type': 'application/json', ...options.headers };
  options.body = JSON.stringify(requestData);
  return await fetchToServer(url, options);
}

export async function getToServer<T>(url: string, requestData: Object, options?: RequestInit) {
  if (!options) { options = {}; }
  options.method = 'GET';
  if (requestData && Object.keys(requestData).length > 0) {
    // Create the URL with the parameters
    url = buildGetURL(url, requestData);
  }
  return await fetchToServer(url, options);
}

export async function fetchToServer<T>(url: string, options?: RequestInit) {
  try {
    options = addJwtTokenToRequest(options);

    // for httpOnly Cookie
    // if (options) {
    //   options.credentials = 'include';
    // } else {
    //   options = { credentials: 'include' };
    // }

    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`Request failed with status: ${response.status}`);
    }

    const data = await response.json();
    // saveJWTToken(data);
    return data as T;
  } catch (error:any) {
    throw new Error(`Fetch error: ${error.message}`);
  }
}
