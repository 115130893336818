import { API_URL, BASE_URL } from '~/EastclinicVueApi/config';
import { getToServer, postToServer, saveJWTToken, deleteJWTToken } from '~/EastclinicVueApi/util/UseFetchToServer';
import type { DataResponse, UserRequest, UserResponse } from '~/EastclinicVueApi';

export default class AdminService {
  public async login(request:UserRequest): Promise<UserResponse> {
    const res:UserResponse = await postToServer(BASE_URL + API_URL + '/control/auth/login', request) as UserResponse;
    saveJWTToken(res.jwtToken);
    return res;
  }

  public logoff() {
    deleteJWTToken();
  }

  public async register(request:UserRequest): Promise<UserResponse> {
    const res:UserResponse = await postToServer(BASE_URL + API_URL + '/control/auth/register', request) as UserResponse;

    return res;
  }

  public async me(): Promise<DataResponse> {
    const res:UserResponse = await getToServer(BASE_URL + API_URL + '/admin/me', []) as UserResponse;

    return res;
  }
}
