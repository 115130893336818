import revive_payload_client_Hh616U8aZb from "/temp/build/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@8.57.0_ioredis@5_cf6e00f07cb2c0abb7adf9476b158282/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_v4WbkcLXm9 from "/temp/build/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@8.57.0_ioredis@5_cf6e00f07cb2c0abb7adf9476b158282/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_pTNnRFWadu from "/temp/build/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@8.57.0_ioredis@5_cf6e00f07cb2c0abb7adf9476b158282/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_vsfSJDYhSQ from "/temp/build/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_vite@6.1.0_@types+node@22.13.4_jiti@2.4.2_sass@1_02ea61c0befaf91ea308e69892c6a616/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_rLWxqPH8pa from "/temp/build/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@8.57.0_ioredis@5_cf6e00f07cb2c0abb7adf9476b158282/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_lIjNOv1rfs from "/temp/build/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@8.57.0_ioredis@5_cf6e00f07cb2c0abb7adf9476b158282/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_hagHl2Ns7C from "/temp/build/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@8.57.0_ioredis@5_cf6e00f07cb2c0abb7adf9476b158282/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_lAR0BrvXr8 from "/temp/build/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@8.57.0_ioredis@5_cf6e00f07cb2c0abb7adf9476b158282/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_KfbI4lR8CT from "/temp/build/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/temp/build/.nuxt/components.plugin.mjs";
import prefetch_client_Reewe2Dcwo from "/temp/build/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@8.57.0_ioredis@5_cf6e00f07cb2c0abb7adf9476b158282/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import motion_WUeVwq65tf from "/temp/build/node_modules/.pnpm/@vueuse+motion@2.2.6_magicast@0.3.5_vue@3.5.13_typescript@5.7.3_/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import nuxt_plugin_iME3KC628D from "/temp/build/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import nuxt3_plugin_mUCuaYMbug from "/temp/build/node_modules/.pnpm/vue-yandex-maps@2.1.6_@vue+runtime-core@3.5.13_nuxt@3.15.4_@parcel+watcher@2.5.1_@types_20619c06cf2d3b6586bafd4a46ed707c/node_modules/vue-yandex-maps/dist/plugins/nuxt3-plugin.js";
import vmask_MNloZULcpN from "/temp/build/src/plugins/vmask.ts";
import nuxtServerInit_LTWY2PUaTk from "/temp/build/src/plugins/nuxtServerInit.ts";
import scrollTop_e7v3LLiez9 from "/temp/build/src/plugins/scrollTop.ts";
import sentryInit_client_D9KT86gqsm from "/temp/build/src/plugins/sentryInit.client.ts";
export default [
  revive_payload_client_Hh616U8aZb,
  unhead_v4WbkcLXm9,
  router_pTNnRFWadu,
  _0_siteConfig_vsfSJDYhSQ,
  payload_client_rLWxqPH8pa,
  navigation_repaint_client_lIjNOv1rfs,
  check_outdated_build_client_hagHl2Ns7C,
  chunk_reload_client_lAR0BrvXr8,
  plugin_vue3_KfbI4lR8CT,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Reewe2Dcwo,
  motion_WUeVwq65tf,
  nuxt_plugin_iME3KC628D,
  nuxt3_plugin_mUCuaYMbug,
  vmask_MNloZULcpN,
  nuxtServerInit_LTWY2PUaTk,
  scrollTop_e7v3LLiez9,
  sentryInit_client_D9KT86gqsm
]