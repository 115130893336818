import { defineStore } from 'pinia';
import { reactive } from 'vue';
import type ServiceCartInterface from '~/EastclinicVueApi/interfaces/ServiceCartInterface';
import type { ServiceData } from '~/EastclinicVueApi';
// import type { TCartServiceItem } from '#build/src/composables/useCartServices';

export const useCartStore = defineStore('cart', () => {
  const cart = reactive<ServiceCartInterface>({});

  const toggleService = (service: ServiceData) => {
    if (getGoodByService(service)) {
      delete cart[service.id];
    } else {
      cart[service.id] = { count: 1, service };
    }
  };

  const add = (service: ServiceData) => {
    if (getGoodByService(service)) {
      if (cart[service.id].count < 100) {
        cart[service.id].count++;
      }
    } else {
      cart[service.id] = { count: 1, service };
    }
  };

  const remove = (service: ServiceData) => {
    const good = getGoodByService(service);
    if (good) {
      if (good.count === 1) {
        delete cart[service.id];
      } else {
        cart[service.id].count--;
      }
    }
  };

  const clear = () => {
    Object.keys(cart).forEach((key) => delete cart[key]);
  };

  const selectedServicesList = (): ServiceData[] | [] => {
    return Object.values(cart).map((good) => good.service) ?? [];
    // return Object.keys(cart).map((key) => cart[key].service);
  };

  const sum = (): number => {
    const sumServices = Object.values(cart).map((good) =>
      (good.service.custom_price > 0) ? good.service.custom_price * good.count : good.service.price * good.count);
    return sumServices.reduce((a, b) => a + b, 0);
  };

  const count = (): number => {
    return Object.keys(cart).length ?? 0;
  };

  const goods = (): ServiceCartInterface => {
    return cart;
  };

  const getGoodByService = (service: ServiceData): { count: number, service: ServiceData } | null => {
    return cart[service.id] ?? null;
  };
  const isSelected = (service:ServiceData):boolean => !!cart[service.id];
  const services = computed<TCartServiceItem[]>(() => (cart) ? Object.values(cart) : []);

  return {
    cart,
    toggleService,
    add,
    remove,
    clear,
    selectedServicesList,
    sum,
    count,
    goods,
    getGoodByService,
    isSelected,
    services
  };
});
