// import type RequestAdapterInterface from '../../interfaces/RequestAdapterInterface';
import type { IPageInfoResponse, PageInfoRequestInterface, PageInfo } from '../../index';
import type CatalogData from '~/interfaces/api/catalogData';
import { API_MODX_URL, BASE_URL } from '~/EastclinicVueApi/config';
import { postToServer, getToServer } from '~/EastclinicVueApi/util/UseFetchToServer';

class PageInfoService {
  public async fetch(request: PageInfoRequestInterface): Promise<IPageInfoResponse> {
    try {
      // const url = 'http://localhost:8000/';
      return await getToServer(BASE_URL + '/system/api/v1/page-info', request) as IPageInfoResponse;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  // service function for get catalog of page
  public getCatalogData(pageInfo:PageInfo) {
    const parents = [];

    /* eslint-disable brace-style */
    if (pageInfo.isCatalog) {
      parents.push(pageInfo.id);
    }
    else if (pageInfo.id === 6766 || pageInfo.id === 6 || (pageInfo.parents && pageInfo.parents[6766])) {
      parents.push(6766);
    }
    else if (pageInfo.id === 3703 || (pageInfo.parents && pageInfo.parents[3703])) {
      parents.push(3703);
    }
    else if (pageInfo.id === 4 || (pageInfo.parents && pageInfo.parents[4])) {
      parents.push(4);
    }
    /* eslint-enable brace-style */

    interface Data {
      resourceId: number
      alphabet: boolean,
      parents: number[] | null,
      structure?: string
    }

    const data: Data = {
      resourceId: pageInfo.id,
      alphabet: true,
      parents: (parents.length > 0) ? parents : null
    };

    if (!data?.parents) {
      data.parents = [6766, 3703, 4];
    }
    if (!data?.structure) {
      data.structure = 'alphabet';
    }

    const requestData = {
      component: 'east',
      action: 'getResourceCatalog',
      ...data
    };

    return postToServer(API_MODX_URL, requestData) as Promise<CatalogData>;
  }
}
// const pageInfoService = new PageInfoService()
export default new PageInfoService();
