import type { IPageInfoRequest, PageInfoRequestInterface } from '../EastclinicVueApi';
import { useClinicsStore } from '~/stores/ClinicsStore';

export default defineNuxtRouteMiddleware(async (to, from) => {
  // skip middleware on initial client load
  // const nuxtApp = useNuxtApp();
  // if (import.meta.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) {
  //   return;
  // }

  const bookingStore = useBookingStore();

  const pageInfoStore = usePageInfoStore();
  // const sessionId = pageInfoStore.sessionId;
  const url = to.fullPath.startsWith('/') ? to.fullPath.slice(1) : to.fullPath;
  const request = <PageInfoRequestInterface>{ url: (url) || 'index' };

  // if (!sessionId && to.path === from.path) {
  //   request.startSession = true;
  // }

  try {
    await pageInfoStore.fetch(request);
  } catch (error) {
    const statusCode = error.message.includes('404') ? 404 : 500;
    if (statusCode === 404) {
      throw createError({
        statusCode: 404,
        message: 'Страница не найдена'
      });
    } else if (statusCode === 500) {
      throw createError({
        statusCode: 500,
        message: 'Ошибка на странице'
      });
    }
  }

  if (pageInfoStore.redirectUrl !== '') {
    const redirectUrl = pageInfoStore.redirectUrl;
    pageInfoStore.redirectUrl = '';
    return navigateTo(redirectUrl, { redirectCode: 301 });
  }

  // await useAsyncData('pageInfo', () => pageInfoStore.fetch(request));

  // after fetch to server, in pageInfoService global contains reactive page info data
  useClinicsStore().setCurrentClinic(pageInfoStore.currentClinicId as number);

  bookingStore.showBookingSuccessMessage = false;
});
