import type { ClinicInterface, IClinicsGetListRequest } from '~/EastclinicVueApi';
import { ClinicsService, PlaceLocationsService } from '~/EastclinicVueApi';

export const useClinicsStore = defineStore('clinicStore', () => {
  const _clinics = ref<Map<number, ClinicInterface>>(new Map());
  const currentClinic = ref<ClinicInterface|null>(null);

  const fetchClinics = async (request:IClinicsGetListRequest = {}) => {
    const { data } = await (new ClinicsService()).fetchClinics(request);
    if (data) {
      for (const c in data) {
        const clinic = data[c] as ClinicInterface;
        _clinics.value.set(clinic.id, clinic);
      }
    }
  };

  const getClinic = (id:number):ClinicInterface|null => {
    return (_clinics.value.get(id)) ?? null;
  };

  const setCurrentClinic = (clinic:ClinicInterface|number|null) => {
    if (typeof clinic === 'number') {
      if (_clinics.value.get(clinic)) { currentClinic.value = _clinics.value.get(clinic) as ClinicInterface; }
    } else {
      currentClinic.value = clinic;
    }
  };

  const getClinicsByIds = (ids: number[]): ClinicInterface[] => {
    if (!_clinics.value.size) { return []; }
    const clinicsByIds: ClinicInterface[] = [];
    ids.forEach((id) => {
      if (getClinic(id)) { clinicsByIds.push(getClinic(id) as ClinicInterface); }
    });
    return clinicsByIds;
  };

  const clinics = computed(():ClinicInterface[] => Array.from(_clinics.value.values()).filter((clinic) => Number(clinic.resid) > 0));

  const clinicsCount = computed(() => {
    return clinics.value.filter((c) => c.id !== 42).length;
  });

  const selectClinic = (clinic:ClinicInterface) => {
    const pageType = usePageInfoStore().getPageType();
    let url = window.location.pathname;

    url = PlaceLocationsService.getUrlWithoutPlace(url);
    if (!['service', 'disease', 'therapy', 'doctors', 'specialist'].find((type) => type === pageType.value) &&
    useNuxtApp().$router.currentRoute.value.name !== 'index'
    ) {
      url = '/vrachi';
    }
    if (url === '/') { url = '/vrachi'; }
    url = PlaceLocationsService.getUrlWithPlace(url, (clinic?.url) ?? '');
    useNuxtApp().$router.push(url);
  };

  return {
    clinics,
    _clinics,
    currentClinic,
    clinicsCount,
    getClinic,
    fetchClinics,
    setCurrentClinic,
    getClinicsByIds,
    selectClinic
  };
});
