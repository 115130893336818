import usePhoneFormat from '../../util/usePhoneFormat';
import type { Patient } from '~/EastclinicVueApi';

// const fio = ref('');
// const phone = ref('+7(');
// const fioError = ref('');
// const phoneError = ref('');
// const surname = ref('');
// const patientName = ref('');

const patientData = reactive<Patient>({
  fio: '',
  phone: '+7 (',
  surname: '',
  patientName: '',
  patronymic: '',
  birthday: ''
});

patientData.birthday = patientData.birthday.split('-').reverse().join('.');

const fioError = ref('');
const phoneError = ref('');

export function usePatientStore() {
  const setPhone = (phone: string): void => {
    patientData.phone = phone;
  };

  const setFio = (fio: string): void => {
    patientData.fio = fio.replace(/[^a-zA-Zа-яЁА-Яё\s]/g, '');
    checkFio();
  };

  const isFilledPhone = ():boolean => {
    return (patientData.phone.length > 17);
  };

  const checkFio = (): boolean => {
    fioError.value = '';
    if (!patientData.fio || patientData.fio === '' || !patientData.fio.match(/[a-zA-Zа-яЁА-Яё]/gm)) {
      fioError.value = 'Пожалуйста, введите имя';
    }
    if (patientData.fio.length > 51) {
      fioError.value = 'Введите более короткое имя';
    }
    // if (!patientData.fio.match(/[аА-яЯaA-zZ]+ [аА-яЯaA-zZ]+/gm)) {
    //   fioError.value = 'Пожалуйста, заполните фамилию';
    // }
    return !fioError.value;
  };

  const checkPhone = (): boolean => {
    phoneError.value = '';
    const phone = patientData.phone;
    const dirtySymbols = phone.replace(/[+0-9()-]/g, '');
    if (dirtySymbols.length) {
      phoneError.value = 'Пожалуйста, введите корректный телефон';
    }
    // phone = usePhoneFormat(phone);
    phoneError.value = '';
    if (!phone || phone === '+7(') {
      phoneError.value = 'Пожалуйста, введите корректный телефон';
    }
    if (phone.length > 18) {
      phoneError.value = 'Пожалуйста, введите корректный телефон';
    }
    if (phone.replace(/[^0-9]/g, '').length !== 11) {
      phoneError.value = 'Пожалуйста, введите корректный телефон';
    }

    return !phoneError.value;
  };

  const validate = (): void => {
    if (!patientData.fio) {
      fioError.value = 'Пожалуйста, введите имя';
    }
  };

  return {
    patientData,
    fioError,
    phoneError,
    setPhone,
    setFio,
    checkFio,
    checkPhone,
    validate,
    isFilledPhone
  };
}
